body,
html {
  margin: 0;
  height: 100%;
  padding: 0;
  overflow-x: hidden;
  font-family: sans-serif;
  background-color: #272727;
}

body .App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.App > header {
  position: initial !important;
}
.App > :last-child {
  flex: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

body .App nav.navbar {
  width: 100vw;
}

label.label--strong {
  margin: 0 0 0 12px;
  font-weight: 600;
  color: #fff;
}

.gunbot-dialog-wrapper div.summary-overview table {
  color: #fff;
}

.gunbot-dialog-wrapper div.MuiPaper-root > ul.MuiList-root /*,
.gunbot-dialog-wrapper:not(div[class$='-wizard']) .MuiPaper-root*/ {
  position: absolute !important;
  background: #1a1a1a;
  color: white !important;
}

.gunthy_wallet.inputGrid.textInputGrid {
  width: 100%;
}

.tabContent-tournament-content > div > div,
.tabContent-telegram-content > div > div,
.tabContent-notifications > div > div,
.tabContent-cancel.orders > div > div,
.tabContent-bot > div > div,
.tabContent-advanced > div > div {
  justify-content: flex-start;
}

div.gunbot-dialog-wrapper .tabContent-gunthywallet-content .gunthy_wallet.inputGrid {
  width: auto;
}

.gunbot-dialog-wrapper.welcome-wizard div.MuiPaper-root,
.gunbot-dialog-wrapper.setup-wizard div.MuiPaper-root {
  background-color: #2e2e2e;
}

.gunbot-dialog-wrapper.trading-settings div.MuiPaper-root {
  background-color: #1a1a1a;
}

.MuiDrawer-root[role='presentation'] {
  top: 40px !important;
  height: calc(100vh - 80px) !important;
  position: fixed !important;
  overflow: hidden !important;
  width: calc(100vw - 80px) !important;
  left: 40px !important;
}

.MuiSelect-root > span {
  position: relative;
  font-size: inherit;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 200;
  line-height: 1;
}

.MuiInput-root {
  width: 100%;
}

.MuiTextField-root input {
  color: white;
}

.MuiTextField-root .MuiInputBase-root.MuiInput-underline input[inputmode='text'],
.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root input[inputmode='text'] {
  height: 38px;
  padding: 9px;
  text-indent: 6px;
  font-weight: 400;
}

.MuiTableCell-root.MuiTableCell-body {
  color: lightgray;
}

.MuiListSubheader-root {
  color: rgba(255, 255, 255, 0.54) !important;
}

.MuiSelect-selectMenu.MuiInputBase-input {
  color: rgba(255, 255, 255, 1);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

div.gunbot-dialog-wrapper > button + div,
div.gunbot-dialog-wrapper > div > button + div {
  box-shadow: none;
}

div.gunbot-dialog-wrapper > button + div > div:first-child,
div.gunbot-dialog-wrapper > div > button + div > div:first-child {
  margin-top: 0 !important;
}

.MuiSelect-selectMenu.MuiInputBase-input em {
  color: rgba(255, 255, 255, 0.54);
}

@media (max-width: 1280px) {
  .trading-terminal-container > .MuiGrid-container > div:first-child {
    display: 'flex';
    justify-content: center;
    order: 2;
  }

  .trading-terminal-container > .MuiGrid-container > div:last-child {
    display: 'flex';
    justify-content: center;
    order: 1;
  }
}

::-webkit-scrollbar {
  display: none;
  max-width: 8px;
  max-height: 8px;
  background: rgba(255,255,255,0.05);
  border-radius: 7px;
}

.show-scroll::-webkit-scrollbar {
  display: block;
}

::-webkit-scrollbar-thumb {
  background: rgba(255,255,255, 0.09);
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255,255,255, 0.2);
}

.gunbot-dialog-wrapper {
  bottom: 0;
  right: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 25;
  overflow-x: hidden;
  background: #2e2e2e;
  text-align: left;
}

.gunbot-terminal-wrapper {
  position: sticky;
  top: 10vh;
  height: 80vh;
  max-width: 1000px;
  max-height: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #1a1a1a;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 1);
  z-index: 10;

  /* changes from master
    height: 80vh;
    max-width: 1000px;
    max-height: 800px;
    width: 100%;
     */
}

.gunbot-dialog-wrapper > div {
  margin-bottom: 0;
  color: #fff;
  overflow-x: hidden;
}

.gunbot-trading-terminal {
  position: sticky;
  top: calc(50vh - 239px);
  width: 500px;
  max-width: 100%;
  opacity: 0.9;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.gunbot-dialog-box {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.MuiIconButton-root.closeButton {
  position: absolute;
  color: red;
  z-index: 10;
  top: 12px;
  right: 16px;
}

/* settings form general */
div.gunbot-dialog-wrapper .inputGrid {
  display: grid;
  grid-column-start: unset;
  grid-column-end: unset;
  /*grid-row: 1;*/
  /*grid-column: 1;*/
}

div.gunbot-dialog-wrapper div.inputGrid > div,
div.gunbot-dialog-wrapper div[class*='tabContent-'] .inputGrid {
  border-radius: 0 4px 0 0;
}

div.gunbot-dialog-wrapper div[class*='tabContent-'] .inputGrid.switchInputGrid {
  border-bottom-right-radius: 4px;
}

div.gunbot-dialog-wrapper .settingsForm {
  display: -ms-grid;
  display: grid;
}

div.gunbot-dialog-wrapper .settingsForm > div {
  display: -ms-grid;
  display: grid;
}

div.settingsForm.overrideSetting button {
  min-height: 60px;
}

div.gunbot-dialog-wrapper .settingsForm div.textInput .MuiTextField-root,
div.gunbot-dialog-wrapper .textInputGrid div.textInput .MuiTextField-root,
div.gunbot-dialog-wrapper .settingsForm div.textInput .MuiSelect-select {
  width: 100%;
  height: 60px;
  border-radius: 0 4px 0 0;
  margin: 0;
  background: hsla(0, 0%, 100%, 0.09);
}

.tabContent-general-content .schedule.inputGrid .MuiTextField-root {
  margin: 0;
}

div.gunbot-dialog-wrapper input[inputmode='text'],
div.gunbot-dialog-wrapper input[inputmode='numeric'] {
  height: 42px;
  /*height: 38px;*/
  padding: 9px;
}

/* settings strategies */
div.gunbot-dialog-wrapper.settings-strategies div[class*='tabContent-'] div.textInput .MuiTextField-root,
div.gunbot-dialog-wrapper.settings-strategies div[class*='tabContent-'] div.textInput .MuiSelect-select {
  width: 100%;
  margin: 0;
  background: hsla(0, 0%, 100%, 0.09);
}

/* settings profile */
.exchangeSwap {
  display: flex;
  flex-direction: column;
}

.exchangeSwap span {
  color: #ccc;
}

.exchangeSwap .errorMessage {
  height: 40px;
  background: #ce3227;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  color: white;
  font-weight: 600;
}

div.gunbot-dialog-wrapper.settings-profile
  div.tabContent-editlicense
  div.tabContent-editlicense-content
  > div
  span
  + div {
  grid-template-columns: 1fr;
}

div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense div.inputGrid:first-child {
  grid-template-columns: 100px 1fr;
}

div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense div.inputGrid:nth-child(2) {
  grid-template-columns: 100px 1fr;
  width: 100%;
}

div.gunbot-dialog-wrapper.settings-profile
  div.tabContent-editlicense
  div.tabContent-editlicense-content
  .inputGrid
  label {
  margin: 20px 0 20px 15px;
  font-size: 14px;
}

div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense div.licenseItem {
  grid-template-columns: 1fr;
  display: grid;
  width: 100%;
}

div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense div.licenseItem > div.row {
  grid-template-columns: 1fr 3fr;
}

div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense .MuiTextField-root,
div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense .MuiSelect-root {
  padding: 0;
  margin: 0;
  border-radius: 0 4px 0 0;
  background: hsla(0, 0%, 100%, 0.09);
}

div.gunbot-dialog-wrapper.settings-autoconfig div.inputGrid.enableAutoconfig ~ div {
  box-shadow: none;
  margin-top: 20px;
}

.tabContent-guiauthentication-content div[class*='Auth-root'] {
  width: calc(100% + 70px);
}

.tabContent-guiauthentication-content div[class*='Auth-root'] > div > div > div:nth-child(3) {
  width: 100%;
}

div.gunbot-dialog-wrapper.settings-strategies div.inputValue-existingStrategy + div {
  margin: 0 -35px;
  width: calc(100% + 70px);
  box-shadow: none;
}

div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] ~ div ~ div > div {
  justify-content: flex-start;
}

.tabContent-guiauthentication-content .inputGrid.image_data,
.tabContent-guiauthentication-content .inputGrid.secret {
  display: block !important;
  width: 100% !important;
  margin-right: 0 !important;
}

/* #TODO: REMOVE RANDOM (NON-STANDARD, MAX-WIDTH & COMBINED) QUERIES */
@media (max-width: 500px) {
  div[class*='tabContent-'] .textInputGrid .MuiInputBase-input .MuiInput-underline:after {
    border-bottom: 4px solid #3f51b5;
    border-radius: 4px;
  }

  div[class*='tabContent-'] .textInputGrid label {
    -ms-grid-row: 1;
    grid-row: 1;
    width: 100%;
  }
}
@media (max-width: 960px) {
  .gunbot-dialog-wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: unset;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  div.gunbot-dialog-wrapper.settings-strategies > div > div > div:first-child {
    /*background: red;*/
  }

  div.gunbot-dialog-wrapper.settings-strategies > div > div > div:first-child > div {
    width: 100vw;
  }

  div.gunbot-dialog-wrapper .settingsForm div.textInputGrid {
    margin-right: 0;
  }

  div.gunbot-dialog-wrapper.settings-strategies div.inputValue-existingStrategy + div {
    width: calc(100% + 0px);
    margin-left: 0px;
  }

  /* GBC */
  div.gunbot-dialog-wrapper .settingsForm div.textInput {
    /*width: 80vw;*/
  }

  div.gunbot-dialog-wrapper
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input:first-child {
    padding-left: 14px;
  }

  .MuiSelect-select.MuiSelect-select + input + svg {
    right: 9px;
  }

  div[class*='tabContent-'] .textInputGrid .textInput {
    position: relative;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color: rgba(255, 255, 255, 0.09);
  }

  /* settings global */
  div.gunbot-dialog-wrapper.dialog-dashboard,
  div.gunbot-dialog-wrapper.settings-global {
    width: calc(100vw + 8px);
  }

  div.gunbot-dialog-wrapper.dialog-dashboard label,
  div.gunbot-dialog-wrapper.settings-global label {
    width: 100%; /* used to be 140px; */
  }

  div.gunbot-dialog-wrapper div.textInput label.MuiFormLabel-root.Mui-focused {
    color: #999;
  }

  div.gunbot-dialog-wrapper div.textInput label.MuiInputLabel-outlined.MuiInputLabel-shrink {
  }

  div.gunbot-dialog-wrapper div.textInput div.MuiAutocomplete-inputRoot fieldset.MuiOutlinedInput-notchedOutline {
    border: 0 solid;
    border-bottom: 4px #3f51b5;
    border-radius: 4px;
  }

  div.gunbot-dialog-wrapper.dialog-dashboard .textInput,
  div.gunbot-dialog-wrapper.settings-global .textInput {
    display: inline;
    width: 60px;
    margin: 0;
  }

  div.gunbot-dialog-wrapper.settings-pairs > div {
    color: rgba(255, 255, 255, 0.5);
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm button,
  div.gunbot-dialog-wrapper.settings-strategies div.settingsForm button {
    margin: 14px 0;
  }

  /* settings strategies */
  div.gunbot-dialog-wrapper.settings-strategies > div > div {
    padding: 30px 20px;
  }
}
/* END #TODO */

@media (min-width: 320px) {
  div.gunbot-dialog-wrapper div[class^='tabContent-'][class$='-content'],
  div.gunbot-dialog-wrapper div[class^='tabContent-'][class$='-content'] > div {
    padding: 0;
    margin: 0;
  }

  div.MuiFormControl-marginNormal {
    margin: 0;
  }

  .MuiSelect-root > span {
    top: 9px;
    left: 6px;
    color: #ffffffbf;
    font-weight: 400;
  }

  .MuiTextField-root .MuiInputBase-root.MuiInput-underline input[inputmode='text'],
  .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root input[inputmode='text'] {
    min-height: 42px;
    text-indent: 0;
  }
/* 
  :not(#settings_search) > div:not(#settings_search) > div:not(#settings_search) > div.MuiAutocomplete-inputRoot.MuiOutlinedInput-root {
    height: 60px;
    padding: unset;
    border-radius: 0 4px 0 0;
    background: hsla(0, 0%, 100%, 0.09);
  } */

  div.gunbot-dialog-wrapper .settingsForm {
    grid-template-columns: 1fr;
    grid-template-rows: 60px;
    grid-column-gap: 0;
    grid-row-gap: 12px;

    width: 100vw;
    margin-right: 0;
    /* margin-bottom: 24px; */
  }

  div.gunbot-dialog-wrapper div.inputGrid {
    align-items: center;
  }

  div.gunbot-dialog-wrapper div.inputGrid,
  div.gunbot-dialog-wrapper .settingsForm > div {
    grid-template-columns: 80px 1fr;
    grid-template-rows: 60px;
    grid-column-gap: 20px;
    height: 60px;
    border-radius: 4px 4px 0 4px;
    background: rgba(255, 255, 255, 0.04);
  }

  div.gunbot-dialog-wrapper .settingsForm > div > label {
    min-width: 100px;
    margin: 0 0 0 12px;
  }

  /* relative container structure */
  /* tabbed content structure, looks & positioning */
  div.gunbot-dialog-wrapper.settings-strategies div[class*='tabContent-'] > div {
    padding: 30px 0px;
  }

  div[class*='tabContent-'] > div span + div {
    max-width: 100vw;
    margin: 0 auto;
  }

  /* GBC div[class*='tabContent-'] > div div:not(.inputValue-connectExchanges) > div > div:first-child,*/
  div[class*='tabContent-'] label {
    width: 100%;
    margin: 0 0 24px 0;
  }

  div[class*='tabContent-'] label.MuiInputLabel-root {
    /*text-align: right;*/
  }

  div[class*='tabContent-'] div.MuiAutocomplete-root label.MuiInputLabel-root {
    /*text-align: left;*/
  }

  div[class*='tabContent-'] .inputGrid {
    grid-template-rows: 1fr 60px;
    width: 100%;
    margin-bottom: 12px;
  }

  div[class*='tabContent-'] .inputGrid label {
    margin: 0 0 0 12px;
  }

  div[class*='tabContent-'] .inputGrid div.textInput {
    height: 60px;
    margin: 0;
  }

  div[class*='tabContent-'] .inputGrid.switchInputGrid {
    grid-template-columns: 160px 1fr;
    grid-template-rows: 60px;
  }

  div[class*='tabContent-'] .inputGrid.switchInputGrid label {
  }

  div[class*='tabContent-'] .inputGrid.switchInputGrid div.textInput {
    width: 50px;
    height: auto;
    margin: 0 auto;
  }

  div[class*='tabContent-'] .inputGrid .MuiInputBase-input {
    width: 100%;
    height: 40px;
    padding: 10px 0;
    border-radius: 4px 0 4px 4px;
    color: white;
    text-indent: 12px;
  }

  /* existing strategies list container */
  div.gunbot-dialog-wrapper div.inputValue-existingStrategy {
    margin-top: 12px;
    margin-bottom: 24px;
  }

  /* specific tabbed content & container */
  div.gunbot-dialog-wrapper div.inputValue-existingStrategy + div {
    margin: 0 -25px;
    width: calc(100% + 50px);
  }
  div.gunbot-dialog-wrapper div.inputValue-existingStrategy + div > div:first-child {
    margin: 0 -40px;
  }
  div.gunbot-dialog-wrapper div.inputValue-existingStrategy > label {
    display: block;
    margin-bottom: 24px;
  }

  /* wizard settings */
  div.gunbot-dialog-wrapper.welcome-wizard div.pairStratSettings,
  div.gunbot-dialog-wrapper.setup-wizard div.pairStratSettings {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-template-rows: 60px;
    grid-gap: 12px;
  }

  div.gunbot-dialog-wrapper div.pairStratSettings div.MuiInputBase-root {
    width: 100%;
    height: 60px;
  }

  div.gunbot-dialog-wrapper div.pairStratSettings .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: -12px;
  }

  div.gunbot-dialog-wrapper div.pairStratSettings div.inputGrid {
    margin: 0;
    align-items: center;
  }

  div.gunbot-dialog-wrapper div.pairStratSettings div.inputGrid.switchInputGrid {
    grid-template-columns: 175px 1fr;
  }

  div.gunbot-dialog-wrapper div.pairStratSettings div.inputGrid label {
    margin: 0 0 0 12px;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.MuiFormControl-root {
    width: 100%;
    margin: 0;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.exchangePairSelect,
  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.strategyName,
  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.strategyMethod {
    grid-template-columns: 90px 1fr;
    margin-bottom: 24px;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.exchangePairSelect {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    background: transparent;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.strategyName div.MuiFormControl-marginNormal {
    margin: 0;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.strategyMethod div.MuiInputBase-root {
    height: 60px;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.MuiStepper-root table {
    color: #fff;
  }

  div.gunbot-dialog-wrapper.setup-wizard table {
    border-spacing: 0 12px;
  }
  div.gunbot-dialog-wrapper.setup-wizard table > tr:first-child td {
    background: transparent;
  }
  div.gunbot-dialog-wrapper.setup-wizard table > tr > td:empty {
    display: none;
  }
  div.gunbot-dialog-wrapper.setup-wizard table > tr > td {
    height: 50px;
  }

  div.gunbot-dialog-wrapper.setup-wizard .summary-overview table > tr > td:first-child {
    border-radius: 4px 0 0 4px;
    background: hsla(0, 0%, 100%, 0.04);
  }
  div.gunbot-dialog-wrapper.setup-wizard .summary-overview table > tr > td:last-child {
    border-radius: 0 4px 4px 0;
    background: hsla(0, 0%, 100%, 0.09);
  }

  div.gunbot-dialog-wrapper.setup-wizard div.stepNavigation {
    margin-top: 24px;
  }

  /* tradingview addon */
  div.gunbot-dialog-wrapper.tradingview-addon > div > button + div {
    padding-top: 75px;
  }

  div.gunbot-dialog-wrapper.tradingview-addon div.inputGrid label {
    margin: 0 0 0 12px;
  }

  div.gunbot-dialog-wrapper.tradingview-addon div.MuiFormControl-root {
    margin: 0;
  }

  /* home-style dashboard */
  div.gunbot-dialog-wrapper.gunbot-home div.gunbot-dialog-box > div.MuiInputBase-root:last-child {
    width: calc(100% - 40px);
    height: 50px;
    top: 150px;
    left: 15px;
    background: #3a3a3a;
  }

  /* dashboard & global settings */
  div.gunbot-dialog-wrapper.dialog-dashboard
    .MuiTextField-root
    .MuiInputBase-root.MuiInput-underline
    input[inputmode='text'] {
    padding: 9px 0;
  }

  div.gunbot-dialog-wrapper.dialog-dashboard > button + div,
  div.gunbot-dialog-wrapper.settings-global > button + div {
    width: calc(100vw + 8px); /* 100vw */
    padding: 88px 0; /* 411 30 */
    margin-top: 0; /* 411 60 */
  }

  /* dashboard */
  div.gunbot-dialog-wrapper.dialog-dashboard div.gunbot-dialog-box > button ~ div:last-child {
    display: none;
  }
  div.gunbot-dialog-wrapper.dialog-dashboard div.gunbot-dialog-box > button ~ div > div:last-child {
    margin: 0;
  }

  /* global settings */
  div.gunbot-dialog-wrapper.settings-global > button + div > div + div {
    max-width: 100vw;
    padding: 15px;
  }

  /* profile settings */
  div.gunbot-dialog-wrapper.settings-profile {
    -ms-overflow-style: none;
  }

  div.gunbot-dialog-wrapper.settings-profile::-webkit-scrollbar {
    display: none;
  }

  /* profile settings UI elements */
  div.gunbot-dialog-wrapper.settings-profile div.inputGrid fieldset {
    height: 60px;
    top: 0;
  }

  div.gunbot-dialog-wrapper.settings-profile div.inputGrid {
    grid-template-columns: 95px 1fr;
    grid-column-gap: 20px;

    height: 60px;
    border-radius: 4px;
    margin: 4px 0;

    background: hsla(0, 0%, 100%, 0.04);
  }

  div.gunbot-dialog-wrapper.settings-profile div.inputGrid label {
    margin: 0;
    margin-left: 6px;
  }

  div.gunbot-dialog-wrapper.settings-profile div.addButton,
  div.gunbot-dialog-wrapper.settings-profile div.addButton button {
    width: 100%;
  }

  div.gunbot-dialog-wrapper.settings-profile div[class^='tabContent-'] button.MuiButton-contained {
    width: 100%;
    max-width: 320px;
    height: 42px;
    margin: 8px;
  }

  div.gunbot-dialog-wrapper.settings-profile div.gunbot-dialog-box > button + div {
    margin-top: 58px;
  }

  div.gunbot-dialog-wrapper.settings-profile div[class^='tabContent-'][class$='-content'] {
  }

  div.gunbot-dialog-wrapper.settings-profile div[class^='tabContent-'] div.switchInputGrid div.textInput {
    width: 100%;
    margin: 0 auto;
  }

  div.gunbot-dialog-wrapper.settings-profile
    .MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
  }

  /* profile settings exchanges tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div {
    padding: 12px 0; /* 24px; */
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content div.inputGrid {
    margin-right: 0;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content div.addButton {
    grid-template-columns: 1fr;
    padding: 6px 0;
  }

  div.gunbot-dialog-wrapper.settings-profile div.inputValue-connectExchanges {
    grid-column: span 3;
  }
  div.gunbot-dialog-wrapper.settings-profile div.inputValue-connectExchanges ~ div {
    margin-bottom: 24px;
  }
  div.gunbot-dialog-wrapper.settings-profile div.inputValue-connectExchanges > div > div {
    width: min-content;
  }

  /* profile settings gunthy wallet tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-gunthywallet-content > div {
    margin: 0;
    padding: 20px 0;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-gunthywallet-content div.inputGrid.gunthy_wallet {
    /*grid-template-columns: 1fr;*/

    grid-template-rows: 40px 60px;
    width: 280px;
    height: 100px;

    margin: 0 0 12px 0;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-gunthywallet-content div.textInput.gunthy_wallet {
    max-width: inherit;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-gunthywallet-content div.textInput.gunthy_wallet input {
    border-radius: 0;
  }

  /* profile settings telegram tab */
  div.gunbot-dialog-wrapper.settings-profile div.inputGrid.BR_TOKEN {
    grid-template-columns: 125px 1fr;
  }

  /* profile settings gui auth tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-guiauthentication-content > div > div > div > div {
    width: auto;
  }

  /* profile settings licensing tab */
  div.gunbot-dialog-wrapper.settings-profile
    div.tabContent-editlicense-content
    > div
    span
    + div
    > div
    > div
    > div
    > div {
    padding: 0;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense div.inputGrid:first-child {
    grid-template-columns: 80px 1fr;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense div.inputGrid:nth-child(2) {
    grid-template-columns: 40px 1fr;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense .MuiSelect-root > span {
    top: 20px;
    left: 0;
  }

  /* settings pairs */
  div.gunbot-dialog-wrapper.settings-pairs .inputGrid:nth-child(2) {
    grid-row: 2;
  }

  div.gunbot-dialog-wrapper.settings-pairs .inputGrid:nth-child(3) {
    grid-row: 3;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm div.textInput .MuiSelect-select {
    width: 131px;
    /*height: 38px;*/
    height: 43px;
    padding: 9px;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm {
    width: 100%;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.pairSettingsForm > div {
    grid-template-columns: 72px 1fr;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm div.MuiInputBase-root,
  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm div.MuiInputBase-root div.MuiInputBase-input {
    width: 100%;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.pairSettingsForm button {
    display: none;
  }

  /* modify pairs container */
  div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] {
    margin: 24px 0;
  }

  div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] > div > div {
    margin-right: auto;
  }

  /* enabled / strategy */
  div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] > div.enabled,
  div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] > div.strategy {
    grid-gap: 20px;
    background: hsla(0, 0%, 100%, 0.04);
  }

  /* individual override settings */
  div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] div:not([class*='SP-paramForm']).inputGrid,
  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid {
    grid-row: unset;
    grid-template-rows: 60px;
    /*grid-template-columns: minmax(min-content, 1fr) 100px 35px;*/
    grid-template-columns: 1fr 84px 34px;

    width: 100%;
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 12px;
    text-align: center;
    background: hsla(0, 0%, 100%, 0.04);
  }

  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid
    label {
    margin: 0;
    margin-left: 12px;

    text-align: left;
    word-break: break-word;
    line-height: initial;
  }

  /* settings strategies */
  div.gunbot-dialog-wrapper.settings-strategies {
    overflow-y: visible;
  }

  div.gunbot-dialog-wrapper.settings-strategies div[class*='tabContent-'] > div span + div {
    margin: 0;
  }

  div.gunbot-dialog-wrapper.settings-strategies > button + div {
    padding-top: 84px;
    overflow-y: hidden;
  }

  div.gunbot-dialog-wrapper.settings-strategies .settingsForm:first-of-type div.inputGrid {
    margin: 0;
  }

  div.gunbot-dialog-wrapper.settings-strategies .settingsForm .inputGrid:nth-child(2) {
    grid-row: 2;
  }

  div.gunbot-dialog-wrapper.settings-strategies .settingsForm .inputGrid:nth-child(3) {
    grid-row: 3;
  }

  /* trading terminal */
  div.gunbot-trading-terminal.trading-terminal--mobile {
    display: block;
    width: 100vw;
    height: 100vh;
    background: #2e2e2e;
    position: fixed;
    top: 0;
  }

  div.gunbot-trading-terminal.trading-terminal--mobile > button + div {
    margin: 0;
  }

  div.gunbot-trading-terminal.trading-terminal--mobile > button + div > div {
    padding: 0;
    margin: 0;
  }

  div.gunbot-trading-terminal.trading-terminal--mobile div.MuiTabs-scroller {
    margin-top: 0;
  }

  div.gunbot-trading-terminal.trading-terminal--desktop.react-draggable {
    display: none;
  }

  div.gunbot-trading-terminal .MuiTabs-flexContainer {
    margin: 0 8px;
  }

  div.gunbot-trading-terminal .MuiButtonGroup-root .MuiButton-outlined.MuiButton-outlinedPrimary {
    margin: 0;
    background-color: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0);
    color: white;
  }

  div.gunbot-trading-terminal .MuiButton-contained.MuiButton-containedPrimary,
  div.gunbot-trading-terminal .MuiButton-outlined.MuiButton-outlinedSecondary {
    border: 0;
  }

  div.gunbot-trading-terminal div[class*='tabContent-'] > div div > div {
    width: calc(45% + 4px);
    margin: 4px 10px 4px 0;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig > div {
    padding-top: 80px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.enableAutoconfig {
    grid-template-columns: 50% 1fr;
    grid-template-rows: 60px;

    margin-bottom: 24px;
    border-radius: 4px;
    background: hsla(0 0% 100% 0.04);
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.enableAutoconfig label {
    margin: 0 0 0 12px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig label + div.enableAutoconfig {
    margin: 0 auto;
  }

  /* settings autoconfig in-tab settings form layout */
  div.gunbot-dialog-wrapper.settings-autoconfig div[class^='tabContent-'][class$='-content'] div.settingsForm {
    grid-template-columns: 1fr;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div[class^='tabContent-'][class$='-content'] div.settingsForm > div {
    width: 100%;
    margin: 0;
    height: 60px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.tabContent-uservariables div.inputGrid {
    grid-template-columns: 90px 1fr;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.tabContent-uservariables div.switchInputGrid {
    grid-template-columns: 180px 1fr;
    margin: 0 0 12px;
  }

  /* settings autoconfig overrides inputs */
  div.gunbot-dialog-wrapper.settings-autoconfig
    div.tabContent-uservariables-content
    div[class*='-setvar'].inputGrid.textInputGrid,
  div.gunbot-dialog-wrapper.settings-autoconfig
    div.tabContent-uservariables-content
    div[class*='-resetvar'].inputGrid.textInputGrid,
  div.gunbot-dialog-wrapper.settings-autoconfig
    div.tabContent-uservariables-content
    div[class*='-setpairvar'].inputGrid.textInputGrid,
  div.gunbot-dialog-wrapper.settings-autoconfig
    div.tabContent-uservariables-content
    div[class*='-resetpairvar'].inputGrid.textInputGrid {
    grid-row: unset;
    grid-template-rows: 60px;
    grid-template-columns: 1fr 120px 40px;
    min-width: 100%;
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 12px;
    text-align: center;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.exchangePairSelect > div.inputGrid {
    grid-template-columns: 100px 1fr;
  }
}

@media (min-width: 360px) {
  div.gunbot-dialog-wrapper.settings-pairs .settingsForm div.textInput .MuiSelect-select {
    width: 160px;
  }
}

@media (min-width: 411px) {
  .MuiSelect-root > span {
    top: 11px;
    left: 16px;
  }

  .MuiTextField-root .MuiInputBase-root.MuiInput-underline input[inputmode='text'],
  .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root input[inputmode='text'] {
    text-indent: 12px;
    font-weight: 400;
  }

  /* relative container structure */
  div[class*='tabContent-'] > div div > div {
    /*width: 100%;*/
    /*margin: 4px 0;*/
  }

  div[class*='tabContent-'] label {
    margin-right: 24px;
  }

  div[class*='tabContent-'] > div div > div > div:first-child,
  div[class*='tabContent-'] label {
    /*min-width: 220px;*/
    /*height: inherit;*/
  }

  div[class*='tabContent-'] label.MuiInputLabel-root {
    text-align: left;
  }

  /* home-style dashboard */
  div.gunbot-dialog-wrapper.gunbot-home div[class*='tabContent-'] {
    margin-top: 70px;
  }

  /* wizard settings */
  div.gunbot-dialog-wrapper.setup-wizard div.MuiFormControl-root {
    margin: 0;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.exchangePairSelect {
    max-width: 100%;
  }

  /* dashboard & global settings */
  div.gunbot-dialog-wrapper.dialog-dashboard > button + div,
  div.gunbot-dialog-wrapper.settings-global > button + div {
    width: 100vw;
    padding: 30px 0;
    margin-top: 60px;
  }

  /*div.gunbot-dialog-wrapper .textInputGrid,*/
  /*div.gunbot-dialog-wrapper .selectInputGrid,*/
  /*div.gunbot-dialog-wrapper .listInputGrid,*/
  /*div.gunbot-dialog-wrapper .switchInputGrid*/
  div.gunbot-dialog-wrapper .inputGrid {
    grid-template-columns: 150px 1fr;
    grid-template-rows: auto;
    width: 100%;
    padding: 0;
    margin: 4px 0;
  }

  div.gunbot-dialog-wrapper .inputGrid:not(:last-child) {
    /* GBC margin-right: 25px;*/
  }

  /* switch input grid control alignment */
  div.gunbot-dialog-wrapper .switchInputGrid label {
    /*margin: 0;*/
  }

  div.gunbot-dialog-wrapper .switchInputGrid div,
  div.gunbot-dialog-wrapper .switchInputGrid .textInput {
    text-align: center;
  }

  /* profile settings */
  div.gunbot-dialog-wrapper.settings-profile div[class*='tabContent-'] > div span + div {
    margin: 0;
  }

  div.gunbot-dialog-wrapper.settings-profile div.inputGrid {
    grid-template-columns: 100px 1fr;
    margin: 0 12px 12px 0;
  }

  div.gunbot-dialog-wrapper.settings-profile div.inputGrid > label {
    margin-left: 12px;
  }

  div.gunbot-dialog-wrapper.settings-profile div.inputValue-connectExchanges ~ div {
    width: 100%;
  }

  /* profile settings gunthy wallet tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-gunthywallet-content > div {
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-gunthywallet-content div.textInput.gunthy_wallet {
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-gunthywallet-content div.inputGrid.gunthy_wallet {
    grid-template-rows: 60px;
    width: 82.5ch;
    height: inherit;
  }

  /*
        because of lack of specificity
        the following section is prone to error
        and should be reviewed as such
        and ideally made more specifically
        related to specific view(s)
    */
  /*
        NUOVO
        explicit tabContent settings grid
    */
  /* notifications */
  div.gunbot-dialog-wrapper.settings-global {
  }

  /* bot */
  div.gunbot-dialog-wrapper.settings-global {
  }

  /* cancel orders */
  div.gunbot-dialog-wrapper.settings-global {
  }

  div.gunbot-dialog-wrapper.settings-global div.tabContent-cancel.orders .inputGrid {
    grid-template-columns: 200px 1fr;
  }

  /* advanced */
  div.gunbot-dialog-wrapper.settings-global {
  }

  /*
        /NUOVO
     */
  div.tabContent-operating .textInputGrid,
  div.tabContent-cancel.orders .textInputGrid {
    display: grid;
    grid-template-columns: 190px 110px;
    margin: 0;
  }

  div.tabContent-operating .textInputGrid label,
  div.tabContent-cancel.orders .textInputGrid label {
    grid-column: 1;
    width: 210px;
  }

  div.tabContent-operating .textInputGrid .textInput,
  div.tabContent-cancel.orders .textInputGrid .textInput {
    grid-column: 2;
  }

  /*
        end section under
        specificity endictment
    */
  /* profile settings */
  /* #TODO GBC div[class*=tabContent-]>div div:not(.inputValue-connectExchanges)>div>div:first-child,*/
  div.gunbot-dialog-wrapper.settings-profile div.inputGrid {
    width: 100%;
    margin: 0 0 12px 0;
  }

  div.gunbot-dialog-wrapper.settings-profile div.inputGrid.exchange > label + div > div.MuiAutocomplete-root {
    margin: 0;
  }

  div.gunbot-dialog-wrapper.settings-profile
    .MuiTextField-root
    .MuiInputBase-root.MuiInput-underline
    input[inputmode='text'] {
    text-indent: 0;
  }

  /* profile settings gui auth tab */
  div.gunbot-dialog-wrapper.settings-profile
    div.tabContent-guiauthentication-content
    > div
    > span
    + div
    > div
    > div
    > div
    > div {
    /*padding: 12px 0;*/
  }

  /* profile settings license tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense .MuiSelect-root {
    padding: 0;
    margin: 0;
    border-radius: 0 4px 0 0;
    background: hsla(0, 0%, 100%, 0.09);
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense .MuiSelect-root > span {
    top: 20px;
  }

  /* settings pairs */
  div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] > div > div {
    margin-right: 12px;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm > div {
    grid-template-columns: 30% calc(70% - 20px);
    height: 60px;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm div.textInput .MuiSelect-select {
    width: 195px;
  }

  div.gunbot-dialog-wrapper.settings-pairs .inputGrid {
    grid-template-columns: 100px 1fr;
    margin: 0 0 4px 0;
    border-radius: 4px;
  }

  div.gunbot-dialog-wrapper.settings-pairs .pairs {
    padding: 0;
  }

  div.gunbot-dialog-wrapper.settings-pairs .pairs label,
  div.gunbot-dialog-wrapper.settings-pairs .inputGrid label {
    margin: 0;
    margin-left: 12px;
    line-height: 20px;
  }

  /* override tab */
  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.pairSettingsForm > div {
    grid-template-columns: 100px 1fr;
  }

  /* individual override settings */
  div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] div:not([class*='SP-paramForm']).inputGrid,
  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid {
    /* grid-template-columns: 165px 1fr 38px; */
    text-align: left;
  }

  /* settings strategies */
  div.gunbot-dialog-wrapper.settings-strategies div[class*='tabContent-'] > div div > div > div:first-child {
    margin: 0;
  }

  /* existing strategies list container */
  div.gunbot-dialog-wrapper.settings-strategies div.inputGrid {
    grid-template-columns: 88px 1fr;
  }

  div.gunbot-dialog-wrapper.settings-strategies div.switchInputGrid {
    grid-template-columns: 160px 1fr;
  }

  div.gunbot-dialog-wrapper.settings-strategies div.inputValue-existingStrategy {
    margin-bottom: 24px;
  }

  /* strategies tab content */
  div[class*='tabContent-'] > div span + div {
  }

  div[class*='tabContent-'] > div span + div:last-child {
  }

  /* global settings notifications */
  div.tabContent-notifications > div div > div {
    width: 210px;
  }

  /* settings autoconfig */
  div.gunbot-dialog-wrapper.settings-autoconfig .inputGrid {
    margin: 0 0 12px;
  }

  div.gunbot-dialog-wrapper div.inputValue-existingStrategy {
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.inputValue-existingStrategy + div {
    margin: 24px -35px 0 -35px;
    width: calc(100% + 70px);
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.inputValue-existingStrategy + div > div {
    margin-top: 0;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.inputValue-existingStrategy + div > div > div:first-child {
    padding: 0;
    margin: 0 0 12px 0;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.inputValue-existingStrategy + div > div > div:nth-child(2) {
    padding: 20px 35px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.settingsForm.createStrategy > div {
    grid-template-columns: 120px 1fr;
    grid-template-rows: 60px;
    height: 60px;
    margin: 0;
  }

  /* settings autoconfig overrides inputs */
  div.gunbot-dialog-wrapper.settings-autoconfig
    div.tabContent-uservariables-content
    div[class*='-setvar'].inputGrid.textInputGrid,
  div.gunbot-dialog-wrapper.settings-autoconfig
    div.tabContent-uservariables-content
    div[class*='-resetvar'].inputGrid.textInputGrid,
  div.gunbot-dialog-wrapper.settings-autoconfig
    div.tabContent-uservariables-content
    div[class*='-setpairvar'].inputGrid.textInputGrid,
  div.gunbot-dialog-wrapper.settings-autoconfig
    div.tabContent-uservariables-content
    div[class*='-resetpairvar'].inputGrid.textInputGrid {
    /*width: calc(50% - 20px);*/
    width: 335px;
    min-width: unset;
  }
}

@media (min-width: 500px) {
  /* tabbed content positioning */
  div[class*='tabContent-'] > div span + div,
  div[class*='tabContent-'] .inputGrid {
    margin: 0;
  }

  div[class*='tabContent-'] .inputGrid .textInput {
    max-width: 100%;
  }

  div.gunbot-dialog-wrapper div[class*='tabContent-'] .inputGrid > label {
    margin: 0 0 0 12px;
  }

  div.gunbot-dialog-wrapper div[class*='tabContent-'] .inputGrid {
    grid-template-columns: 140px 1fr;
    grid-template-rows: 60px;
    grid-column-gap: 20px;
    max-width: unset;
    border-radius: 4px 4px 0 4px;
    margin: 0 0 20px 0;
    background: hsla(0, 0%, 100%, 0.04);
  }

  /* home-style dashboard */
  div.gunbot-dialog-wrapper.gunbot-home div.gunbot-dialog-box > div.MuiInputBase-root:last-child {
    width: calc(50% - 40px);
    height: 50px;
    left: unset;
    right: 23px;
    top: 90px;
  }

  div.gunbot-dialog-wrapper.gunbot-home div[class*='tabContent-'] {
    margin-top: 0;
  }

  div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] ~ div {
    margin-bottom: 24px;
  }

  div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] ~ div {
    width: 100%;
  }

  /* common tab content positioning & such */
  div.gunbot-dialog-wrapper div[class*='tabContent-'] .inputGrid > label {
    margin: 0 0 0 12px;
  }

  div.gunbot-dialog-wrapper div[class*='tabContent-'] .inputGrid {
    grid-template-columns: 140px 1fr;
    grid-template-rows: 60px;
    grid-column-gap: 20px;

    width: 340px;
    max-width: unset;

    border-radius: 4px 4px 0 4px;
    margin: 0 0 20px 0;
    background: hsla(0, 0%, 100%, 0.04);
  }

  div.gunbot-dialog-wrapper div[class*='tabContent-'] .separator-container .inputGrid:nth-child(odd) {
    margin: 0 20px 20px 0;
  }

  div.gunbot-dialog-wrapper div.inputGrid ~ div.separator-container {
    grid-column: 1 / -1;
  }

  /* tradingview addon */
  div.gunbot-dialog-wrapper.tradingview-addon > div > button + div {
    padding-top: 42px;
    margin-top: 0;
  }

  /* global settings */
  div.gunbot-dialog-wrapper.settings-global div[class*='tabContent-'] div.inputGrid {
    width: 100%;
  }

  div.gunbot-dialog-wrapper.settings-global div[class*='tabContent-'] div.inputGrid:nth-child(odd) {
    margin: 0 15px 15px 0;
  }

  div.gunbot-dialog-wrapper.settings-global div[class*='tabContent-'] > div > span + div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }

  div.gunbot-dialog-wrapper.settings-global div.tabContent-advanced div.inputGrid.withdraw_threshold {
    grid-column: 2;
    grid-row: 1;

    margin: 0 0 15px 0;
  }

  div.gunbot-dialog-wrapper.settings-global div.tabContent-advanced div.inputGrid.withdraw_address {
    grid-column: span 2;
    grid-row: 2;
    /*grid-column-start: 1;*/
    /*grid-column-end: 3;*/
    width: auto;
  }

  /* profile settings */
  div.gunbot-dialog-wrapper.settings-profile {
  }

  div.gunbot-dialog-wrapper.settings-profile div.gunbot-dialog-box > button + div {
    margin-top: 0; /* used to be 30px */
  }

  div.gunbot-dialog-wrapper.settings-profile div.gunbot-dialog-box > button + div > div:first-child {
    margin: -30px 0 0;
  }

  /* profile settings exchanges tab */
  div.tabContent-exchanges-content div.inputGrid {
    grid-row: unset;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div {
    padding: 24px;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  div.gunbot-dialog-wrapper.settings-profile
    div.tabContent-exchanges-content
    > div
    > div:last-child:not(.separator-container),
  div.gunbot-dialog-wrapper.settings-profile
    div.tabContent-exchanges-content
    > div
    > div:nth-last-child(2):not(.separator-container),
  div.gunbot-dialog-wrapper.settings-profile
    div.tabContent-exchanges-content
    > div
    > div
    > div:last-child:not(.separator-container),
  div.gunbot-dialog-wrapper.settings-profile
    div.tabContent-exchanges-content
    > div
    > div
    > div:nth-last-child(2):not(.separator-container) {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-column: span 2;
    max-width: 90vw;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > div .inputGrid {
    grid-template-columns: 130px 1fr;
    width: 100%;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content div.addButton {
    grid-column: 1;
  }

  div.tabContent-exchanges-content div.inputGrid.exchange {
    grid-column: 1;
    grid-row: 1;
  }

  div.tabContent-exchanges-content div.inputGrid.masterKey {
    grid-row: 2;
  }

  div.tabContent-exchanges-content div.inputGrid.key {
    grid-column: 2;
    grid-row: 2;
  }

  div.tabContent-exchanges-content div.inputGrid.masterSecret {
    grid-row: 3;
  }

  div.tabContent-exchanges-content div.inputGrid.secret {
    grid-column: 2;
    grid-row: 3;
  }

  div.tabContent-exchanges-content div.inputGrid.delay {
    grid-row: 4;
  }

  div.tabContent-exchanges-content div.inputGrid.fees {
    grid-column: 2;
    grid-row: 4;
  }

  div.tabContent-exchanges-content div.addButton {
    grid-column: 1;
  }

  div.tabContent-exchanges-content div.inputGrid.addButton:not(.textInput) {
    grid-column: 1;
    grid-row: 5;
  }

  div.gunbot-dialog-wrapper.settings-profile
    div.tabContent-exchanges-content
    div.inputValue-connectExchange
    div.gunbot-dialog-wrapper.settings-profile
    div.tabContent-exchanges-content
    div.inputValue-connectExchanges
    ~ div
    > div
    > div.inputGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60px 1fr;
    grid-column-gap: 20px;
  }

  /* profile settings gui auth tab */
  div.settings-profile div.tabContent-guiauthentication-content {
  }

  div.settings-profile div.tabContent-guiauthentication-content > div > span ~ div > div > div > div > div > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  div.gunbot-dialog-wrapper.settings-profile div[class*='tabContent-'] > div span + div {
  }

  div.settings-profile div.tabContent-guiauthentication-content div.inputgrid {
    grid-template-columns: 140px 1fr;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-guiauthentication-content > div > span + div span + div {
    grid-column-gap: 20px;
  }

  /* profile settings telegram tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-telegram-content .inputGrid {
    width: calc(20% - 10px);
  }

  div.gunbot-dialog-wrapper div.tabContent-guiauthentication-content .inputGrid:nth-child(odd),
  div.gunbot-dialog-wrapper div.tabContent-telegram-content .inputGrid:nth-child(odd) {
    margin: 0 15px 20px 0;
  }

  div.gunbot-dialog-wrapper.settings-profile div.inputGrid.BR_TOKEN {
    grid-template-columns: 140px 1fr;
  }

  /* profile settings license tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense div.licenseItem {
    grid-template-columns: 270px 3fr;
    grid-template-rows: 60px;
    grid-column-gap: 20px;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense .MuiSelect-root > span {
    /*top: 11px;*/
    left: 0;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div[class*='tabContent-'] div.inputGrid.newFilter,
  div.gunbot-dialog-wrapper.settings-autoconfig div[class*='tabContent-'] div.inputGrid.filterName {
    width: 460px;
  }

  /* common settings forms */
  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm button,
  div.gunbot-dialog-wrapper.settings-strategies div.settingsForm button {
    grid-column-end: 3;
    grid-column-start: 1;
    width: 50%;
    margin: 0 auto;
  }

  div.gunbot-dialog-wrapper.settings-pairs > div > div > div:first-child > div,
  div.gunbot-dialog-wrapper.settings-pairs .settingsForm,
  div.gunbot-dialog-wrapper.settings-strategies .gunbot-dialog-box > div > div > div:first-child > div,
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  /*div.gunbot-dialog-wrapper.settings-strategies .settingsForm,*/
  div.gunbot-dialog-wrapper.settings-strategies
    .gunbot-dialog-box
    > div
    > div
    > div:first-child
    > div:not(.settingsForm) {
    grid-template-columns: 1fr;
  }

  /* settings pairs */
  div.gunbot-dialog-wrapper.settings-pairs .gunbot-dialog-box {
    padding-top: 8px;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm div.strategy .MuiInputBase-root,
  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm div.strategy .MuiSelect-select {
    width: 100%;
  }

  /* override tab */
  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.pairSettingsForm div {
    grid-row: 1;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.pairSettingsForm > div.enabled {
    grid-column: 2;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.pairSettingsForm > div.strategy {
    grid-column: 1;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.pairSettingsForm > div label {
    grid-column: 1;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.pairSettingsForm > div > div {
    grid-column: 2;
  }

  div.gunbot-dialog-wrapper.settings-pairs .inputGrid {
    /*grid-template-columns: minmax(min-content, max-content) 1fr 50px;*/
  }

  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid {
    max-width: calc(50% - 10px);
    margin: 0 0 20px 0;
  }

  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid:nth-of-type(even) {
    margin: 0 20px 20px 0;
  }

  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid:not(:first-child) {
    /*margin-right: 20px;*/
  }

  /* settings strategies */
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm .inputGrid:nth-child(3) {
    grid-row: 2;
  }

  div.gunbot-dialog-wrapper.settings-strategies {
    overflow: visible;
  }

  div.gunbot-dialog-wrapper div[class*='tabContent-'] .separator-container + .inputGrid {
    margin: 0 20px 20px 0;
  }

  /* main settings form grid elements */
  div.gunbot-dialog-wrapper.settings-pairs .settingsForm > .pairs,
  div.gunbot-dialog-wrapper.settings-pairs .settingsForm > .exchange,
  div.gunbot-dialog-wrapper.settings-pairs .settingsForm > .strategy,
  div.gunbot-dialog-wrapper.settings-strategies .nickname,
  div.gunbot-dialog-wrapper.settings-strategies .buytype,
  div.gunbot-dialog-wrapper.settings-strategies .selltype {
    grid-template-columns: 85px 1fr;
    max-width: unset;
    margin: 0;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm > .pairs,
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm > .nickname {
    grid-row: 2;
    grid-column: 1;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm > .exchange,
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm > .buytype {
    grid-column: 1;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm > .strategy,
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm > .selltype {
    grid-column: 2;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm .exchange.inputGrid:nth-child(2),
  div.gunbot-dialog-wrapper.settings-pairs .settingsForm .strategy.inputGrid:nth-child(3),
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm .buytype.inputGrid:nth-child(2),
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm .selltype.inputGrid:nth-child(3) {
    grid-row: 1;
  }

  /* strategies settings form grid contents */
  div.gunbot-dialog-wrapper.settings-strategies .selltype > label,
  div.gunbot-dialog-wrapper.settings-strategies .buytype > label {
    grid-column: 1;
  }

  div.gunbot-dialog-wrapper.settings-strategies .selltype.textInput,
  div.gunbot-dialog-wrapper.settings-strategies .buytype.textInput {
    grid-column: 2;
    grid-row: 1;
  }

  /* main settings form button */
  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm button,
  div.gunbot-dialog-wrapper.settings-strategies div.settingsForm button {
    grid-column-end: unset;
    grid-column-start: unset;
    grid-row: 2;
    width: 80%;
  }

  /* existing strategies list container */
  div.gunbot-dialog-wrapper.settings-strategies div.inputValue-existingStrategy {
    margin-bottom: 40px;
  }

  /* settings autoconfig */
  div.gunbot-dialog-wrapper.settings-autoconfig > div {
    padding-top: 40px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.separator-container {
    grid-column: 1 / -1;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig > button + div > span + div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    margin-top: 24px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.inputGrid.enableAutoconfig {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.inputGrid.enableAutoconfig ~ div {
    grid-column: 1;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.settingsForm.createStrategy {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    width: auto;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.inputValue-existingStrategy + div > div > div:nth-child(2) {
    padding: 20px 25px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div[class^='tabContent-'][class$='-content'] button {
    height: 60px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig
    div[class*='tabContent-']
    > div
    > span
    + div
    > div:not(.separator-container) {
    margin: 0 18px 18px 0;
  }

  /* settings autoconfig in-tab settings form layout */
  div.gunbot-dialog-wrapper.settings-autoconfig div[class^='tabContent-'][class$='-content'] div.settingsForm {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div[class^='tabContent-'][class$='-content'] div.settingsForm > div {
    width: 100%;
    margin: 0;
  }

  /* autoconfig specific exceptions */
  div.gunbot-dialog-wrapper.settings-autoconfig div.inputGrid.setVariableToggle {
    width: 340px;
  }

  /* wizard settings */
  div.gunbot-dialog-wrapper.welcome-wizard div.pairStratSettings,
  div.gunbot-dialog-wrapper.setup-wizard div.pairStratSettings {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-template-rows: 60px;
    grid-gap: 12px;
  }

  div.gunbot-dialog-wrapper div.pairStratSettings div.inputGrid {
    grid-template-columns: 124px 1fr;
    margin: 0;
    align-items: center;
  }

  div.gunbot-dialog-wrapper div.pairStratSettings div.inputGrid label {
    margin: 0 0 0 12px;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.exchangePairSelect,
  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.strategyName,
  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.strategyMethod {
    grid-template-columns: 140px 1fr;
    max-width: 420px;
    margin-bottom: 24px;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.exchangePairSelect {
    grid-template-columns: 1fr 1fr;
    max-width: unset;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.MuiInputBase-root,
  div.gunbot-dialog-wrapper.setup-wizard div.MuiTextField-root {
    width: 100%;
    height: 60px;
    margin: 0;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.switchInputGrid {
    grid-template-columns: 1fr 80px;
  }

  /* edit existing strategies tab content */
  div[class*='tabContent-'] > div span + div {
    max-width: 100%;
    margin: 0;
  }

  /* trading terminal */
  div.gunbot-trading-terminal.trading-terminal--mobile {
    display: none;
  }

  div.gunbot-trading-terminal.trading-terminal--desktop.react-draggable {
    display: flex;
  }

  /* general settings tab content input styling */
  div[class*='tabContent-'] .inputGrid {
    display: grid;
    grid-template-columns: minmax(min-content, max-content) 1fr 20px;
    grid-template-rows: 55px;
    width: auto;
    margin-bottom: 20px;
  }

  .switchInputGrid ~ div:empty + div:empty {
    display: none;
  }

  /* individual override settings */
  div.gunbot-dialog-wrapper.settings-pairs div[class*='inputValue-'] div:not([class*='SP-paramForm']).inputGrid,
  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid {
    grid-template-columns: 100px 1fr 38px;
    text-align: left;
  }
}

@media (min-width: 600px) {
  /* general UI elements */
  .MuiTextField-root .MuiInputBase-root.MuiInput-underline input[inputmode='text'],
  .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root input[inputmode='text'] {
    text-indent: 18px;
  }

  /* strategies settings */
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  div.gunbot-dialog-wrapper.settings-strategies .nickname,
  div.gunbot-dialog-wrapper.settings-strategies .buytype,
  div.gunbot-dialog-wrapper.settings-strategies .selltype {
    grid-template-columns: 85px 1fr;
    max-width: unset;
    height: 60px;
    margin: 0;
  }

  div.gunbot-dialog-wrapper.settings-strategies .settingsForm > .nickname {
    grid-row: 2;
    grid-column: 1;
  }

  div.gunbot-dialog-wrapper.settings-strategies .settingsForm > .buytype {
    grid-column: 1;
  }

  div.gunbot-dialog-wrapper.settings-strategies .settingsForm > .selltype {
    grid-column: 2;
  }

  div.gunbot-dialog-wrapper.settings-strategies .settingsForm .buytype.inputGrid:nth-child(2),
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm .selltype.inputGrid:nth-child(3) {
    grid-row: 1;
  }
}

@media (min-width: 690px) {
  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.exchangePairSelect > div.inputGrid {
    grid-template-columns: 160px 1fr;
  }
}

@media (min-width: 768px) {
  /* view: dashboard */
  div.gunbot-dialog-wrapper.dialog-dashboard div.gunbot-dialog-box > button ~ div:last-child {
    display: block;
  }

  div.gunbot-dialog-wrapper div.inputValue-existingStrategy + div > div:first-child {
    margin: 0 -15px;
  }

  div.gunbot-dialog-wrapper.settings-strategies div[class*='tabContent-'] > div {
    padding: 30px 20px;
  }
}

@media (min-width: 1024px) {
  div.gunbot-dialog-wrapper > div {
    background: unset;
  }

  div.gunbot-dialog-box {
    width: 100%;
  }

  div.gunbot-loading-box {
    /* width: 30%; */
  }

  div.gunbot-dialog-wrapper > .gunbot-dialog-box {
    padding-top: 0 !important;
  }

  /* generic tabbed input type styling */
  div[class*='tabContent-'] .inputGrid .MuiInputBase-input {
    height: 60px;
    padding: 0;
  }

  div[class*='tabContent-'] div.textInput .MuiSelect-select {
    height: 42px;
    padding: 9px 0;
  }

  /* common settings forms elements */
  div.gunbot-dialog-wrapper .settingsForm {
    grid-template-rows: 60px;
  }

  div.gunbot-dialog-wrapper div.settingsForm > div {
    grid-template-columns: 100px 1fr;
    padding: 0;
    margin: 0;
  }

  div.gunbot-dialog-wrapper div.inputGrid {
    grid-template-columns: 140px 1fr;
    grid-column-start: unset;
    grid-column-end: unset;
    grid-row: unset;
    grid-column: unset;
  }

  div.gunbot-dialog-wrapper div.settingsForm .inputGrid {
    grid-row: 1;
  }

  div.gunbot-dialog-wrapper div.settingsForm .inputGrid .textInput .MuiInputBase-root {
    width: 100%;
  }

  div.settingsForm.overrideSetting button {
    min-width: 150px;
  }

  /* wizard settings */
  div.gunbot-dialog-wrapper.welcome-wizard div.pairStratSettings,
  div.gunbot-dialog-wrapper.setup-wizard div.pairStratSettings {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-template-rows: 60px;
    grid-gap: 12px;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.exchangePairSelect,
  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.strategyName,
  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.strategyMethod {
    margin-bottom: 24px;
  }

  div.gunbot-dialog-wrapper.setup-wizard div.inputGrid.exchangePairSelect {
    grid-template-columns: 2fr 2fr 1fr;
  }

  /* global settings */
  div.gunbot-dialog-wrapper.settings-global div[class*='tabContent-'] > div > span + div {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-template-rows: 60px;
  }

  div.gunbot-dialog-wrapper.settings-global div[class*='tabContent-'] div.inputGrid,
  div.gunbot-dialog-wrapper.settings-global div[class*='tabContent-'] div.inputGrid:nth-child(odd),
  div.gunbot-dialog-wrapper.settings-global div.tabContent-advanced div.inputGrid.withdraw_threshold {
    margin: 0;
  }

  div.gunbot-dialog-wrapper.settings-global div[class*='tabContent-'] div.separator-container {
    grid-column-end: none;
  }

  div.gunbot-dialog-wrapper.settings-global > button + div {
    margin: 0;
    padding-top: 30px;
  }

  div.gunbot-dialog-wrapper.settings-global > button + div > div + div {
    width: calc(100vw - 80px);
  }

  div.gunbot-dialog-wrapper.settings-global div.inputGrid.withdraw_address {
    grid-template-columns: 140px 1fr;
  }

  /* global settings tab-specific exceptions */
  div.gunbot-dialog-wrapper.settings-global div.tabContent-advanced div.inputGrid.withdraw_threshold {
    grid-column: unset;
  }

  /* general settings tab content input(Grid) styling
        textInputGrid
        selectInputGrid
        listInputGrid
        switchInputGrid
    */
  div[class*='tabContent-'] .inputGrid {
    grid-template-columns: minmax(min-content, max-content) 1fr 50px;
  }

  div.gunbot-dialog-wrapper div[class*='tabContent-'] .inputGrid {
    min-width: 270px;
  }

  div.gunbot-dialog-wrapper.settings-strategies div[class*='tabContent-'] .inputGrid > label {
    margin: 0 0 0 12px;
  }

  div.gunbot-dialog-wrapper.settings-strategies div[class*='tabContent-'] .inputGrid {
    grid-template-columns: 125px 1fr;
    margin: 0 20px 20px 0;
  }

  div.gunbot-dialog-wrapper div[class*='tabContent-'] div.separator-container {
    grid-column: 1 / -1;
  }

  /* tradingview addon */
  div.gunbot-dialog-wrapper.tradingview-addon div[class*='tabContent-'] .inputGrid {
    margin: 0 20px 20px 0;
  }

  div.gunbot-dialog-wrapper.tradingview-addon > button + div > div:last-child {
    margin-top: 24px;
  }

  /* settings profile */
  div.settings-profile div[class^='tabContent-'][class$='-content'] {
    margin-top: 12px;
  }

  div.settings-profile div[class*='tabContent-'] .inputGrid {
    width: 270px;
  }

  /*div.gunbot-dialog-wrapper.settings-profile div[class*=tabContent-] > div span + div,*/
  div.gunbot-dialog-wrapper.settings-profile div.setting-exchanges-info span + div {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-column-gap: 20px;
  }

  div.gunbot-dialog-wrapper.settings-profile div.setting-exchanges-info div.bitrage,
  div.gunbot-dialog-wrapper.settings-profile div.setting-exchanges-info div.secrets {
    grid-row: 3;
    margin: auto;
  }

  /* settings profile exchange tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div span + div > div.inputGrid {
    grid-row: auto;
    grid-column: auto;
    width: 100%;
  }

  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > span + div {
    grid-template-columns: 1fr 1fr;
  }

  div.gunbot-dialog-wrapper.settings-profile
    div.tabContent-exchanges-content
    > div
    > span
    + div
    > div:last-child
    > div
    > span
    + div {
    display: block;
  }

  /* settings profile gunthy wallet tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-gunthywallet-content div.inputGrid.gunthy_wallet {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  /* settings profile gui auth tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-guiauthentication-content > div > span + div {
    grid-template-columns: 1fr;
  }

  div.gunbot-dialog-wrapper.settings-profile
    div.tabContent-guiauthentication-content
    > div
    > span
    + div
    > div
    span
    + div {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-column-gap: 20px;
  }

  /* settings profile gui auth tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-telegram-content div.inputGrid.TOKEN,
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-telegram-content div.inputGrid.chat_id,
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-telegram-content div.inputGrid.admin_id {
    grid-template-columns: 70px 1fr;
  }

  /* profile settings license tab */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-editlicense div.licenseItem {
    grid-template-columns: 1fr 3fr;
  }

  /* styles shared by pairs & strategies settings forms */
  div.gunbot-dialog-wrapper.settings-pairs > div > div > div:first-child > div,
  div.gunbot-dialog-wrapper.settings-pairs .settingsForm,
  div.gunbot-dialog-wrapper.settings-strategies .gunbot-dialog-box > div > div > div:first-child > div,
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm button,
  div.gunbot-dialog-wrapper.settings-strategies div.settingsForm button {
    grid-row: 1;
    grid-column: 4;
    width: 100%;
  }

  /* settings pairs */
  div.gunbot-dialog-wrapper.settings-pairs .gunbot-dialog-box {
    padding-top: 0;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm > div {
  }

  div.gunbot-dialog-wrapper.settings-pairs .inputGrid {
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm .inputGrid {
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm .inputGrid:nth-child(2) {
    grid-column: 2;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm .inputGrid:nth-child(3) {
    grid-column: 3;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm div.textInput .MuiSelect-select {
    /*width: 167px;*/
    width: 100%;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.overrideSetting {
    grid-template-columns: repeat(2, 1fr) 120px;
    max-width: 700px;
    margin-bottom: 42px;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.overrideSetting button {
    grid-column: 3;
  }

  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid,
  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid:nth-of-type(even) {
    grid-template-columns: minmax(min-content, 100px) 1fr 38px;
    max-width: 270px;
    margin: 0 20px 12px 0;
  }

  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid:nth-of-type(3n + 1) {
    /*margin-right: 0;*/
  }

  div.gunbot-dialog-wrapper.settings-pairs
    div[class*='inputValue-']
    ~ div
    ~ div
    > div
    > div:not([class*='SP-paramForm']).inputGrid
    label {
    text-align: left;
  }

  /* settings strategies */
  div.gunbot-dialog-wrapper.settings-pairs > div > div > div:first-child > div,
  div.gunbot-dialog-wrapper.settings-pairs .settingsForm,
  div.gunbot-dialog-wrapper.settings-strategies .gunbot-dialog-box > div > div > div:first-child > div,
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm {
    grid-template-columns: repeat(3, 1fr) 120px;
    width: 100%;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm > .pairs,
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm .inputGrid {
    grid-row: 1;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm .inputGrid:nth-child(2),
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm .inputGrid:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm .inputGrid:nth-child(3),
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm .inputGrid:nth-child(3) {
    grid-column: 3;
    grid-row: 1;
  }

  div.gunbot-dialog-wrapper.settings-pairs .inputGrid,
  div.gunbot-dialog-wrapper.settings-strategies .inputGrid {
    grid-column-start: unset;
    grid-column-end: unset;
    grid-row: 1;
    grid-column: 1;
  }

  div.gunbot-dialog-wrapper.settings-strategies .buytype,
  div.gunbot-dialog-wrapper.settings-strategies .selltype {
    grid-row: 1;
    margin-right: 0;
  }

  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm.createTradingPair > .pairs {
    grid-column: 2;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm > .exchange {
    grid-column: 1;
  }

  div.gunbot-dialog-wrapper.settings-strategies .buytype {
    grid-column: 2;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm > .strategy,
  div.gunbot-dialog-wrapper.settings-strategies .selltype {
    grid-column: 3;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.enableAutoconfig.inputGrid {
    width: 275px;
    height: 60px;
    margin-right: auto;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.settingsForm.createStrategy {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-template-rows: 60px;
    grid-gap: 20px;
    width: 100%;
    max-width: unset;
    margin-bottom: 6px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.settingsForm.createStrategy > div {
    grid-column: span 2;
    grid-row: 1;
    width: 100%;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div[class*='tabContent-'] div.inputGrid.jobName {
    grid-template-columns: 75px 1fr;
    width: 560px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.settingsForm.createStrategy > div.jobType {
    grid-template-columns: 132px 1fr;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div[class*='tabContent-'] .inputGrid {
    grid-template-columns: 110px 1fr;
    width: 270px;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.inputValue-existingStrategy + div > div > div:nth-child(2) {
    max-width: calc(100% - 80px);
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div[class^='tabContent-'][class$='-content'] div.settingsForm {
    grid-template-columns: 335px 335px 180px 1fr;
  }

  div[class*='tabContent-'] > div div:not(.inputValue-connectExchanges) > div > div:first-child.MuiInput-root {
    margin: 0;
    background: hsla(0, 0%, 100%, 0.09);
    width: 100%;
  }

  /* settings autoconfig tab-specific inputs & containers exceptions */
  div.gunbot-dialog-wrapper.settings-autoconfig div.tabContent-general-content {
  }

  div.gunbot-dialog-wrapper.settings-autoconfig
    div.tabContent-general-content
    > div
    > span
    + div
    > div:not(.separator-container) {
    height: 60px;
  }

  /* user variables */
  div.gunbot-dialog-wrapper.settings-autoconfig div.tabContent-uservariables div.separator-container {
    margin: 14px 0;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.tabContent-uservariables .switchInputGrid {
    grid-template-columns: 180px 1fr;
  }

  div.gunbot-dialog-wrapper.settings-autoconfig div.inputGrid.setVariableToggle {
    /*width: 340px;*/
  }
}

@media (min-width: 1200px) {
  /* form layout configuration */
  div.gunbot-dialog-wrapper.settings-pairs div.settingsForm button,
  div.gunbot-dialog-wrapper.settings-strategies div.settingsForm button {
    grid-row: 1;
    grid-column: 4;
    max-width: 60%;
  }

  /* general input looks & positioning  */
  .MuiTablePagination-selectRoot {
    height: 24px !important;
    width: 100px !important;
  }

  .MuiTableCell-sizeSmall {
    padding-left: 10px !important;
  }

  div.tabContent-autoconfig .MuiFormControl-root .MuiTextField-root {
    height: 32px !important;
  }

  .MuiFormControl-root .MuiTextField-root {
    width: 100%;
    height: 60px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 0;
  }

  /* auto height for multi select boxes on import page  */
  div.gunbot-dialog-wrapper .importSelector .MuiInputBase-root {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  div.gunbot-dialog-wrapper.gunbot-home div.gunbot-dialog-box > div.MuiInputBase-root:last-child {
    max-width: 450px;
  }

  /* add pairs wizard settings */
  div.gunbot-dialog-wrapper.setup-wizard div.gunbot-dialog-box.MuiPaper-root {
    width: 100%;
    margin: -24px 0 24px;
    background: #885b5b00;
  }

  /* tabbed content positioning */
  div.gunbot-dialog-wrapper.settings-pairs div[class*='tabContent-'] .inputGrid,
  div.gunbot-dialog-wrapper.settings-strategies div[class*='tabContent-'] .inputGrid {
    grid-template-columns: 140px 1fr;
    margin: 0 20px 20px 0;
  }

  div.gunbot-dialog-wrapper.settings-global div[class*='tabContent-'] div.inputGrid {
    margin: 0;
  }

  /* pairs settings looks & positioning */
  div.gunbot-dialog-wrapper.settings-pairs .settingForm .inputGrid .textInput {
    background: transparent;
  }

  div.gunbot-dialog-wrapper.settings-pairs .settingsForm div.textInput .MuiSelect-select {
    width: 100%;
    height: 42px;
  }

  div.gunbot-dialog-wrapper.settings-pairs .strategy.textInput {
    height: 60px;
  }

  div.gunbot-dialog-wrapper.settings-pairs .inputGrid:nth-child(2) {
    margin-right: 24px;
  }

  /* main settings form looks & positioning */
  div.gunbot-dialog-wrapper.settings-pairs .settingsForm,
  div.gunbot-dialog-wrapper.settings-strategies .gunbot-dialog-box > div > div > div:first-child > div,
  div.gunbot-dialog-wrapper.settings-strategies .settingsForm,
  div.gunbot-dialog-wrapper.settings-strategies .gunbot-dialog-box > div > div > div:first-child > div {
    grid-template-columns: repeat(4, 1fr);
  }

  div.gunbot-dialog-wrapper.settings-pairs .switchInputGrid:nth-child(2) {
    margin-right: 0;
  }

  /* global settings */
  div.gunbot-dialog-wrapper.settings-global div.tabContent-advanced div.inputGrid.withdraw_address {
    grid-template-columns: 140px 1fr;
    grid-column: span 2;
    grid-row: unset;
  }

  /* profile settings */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > div:last-child,
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > div:nth-last-child(2),
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > div > div:last-child,
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > div > div:nth-last-child(2) {
    grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
  }
}

/*
@media (min-width: 1280px) {
    div.gunbot-dialog-wrapper .settingsForm {
        display: grid;
        margin-right: 20px;
    }
}
*/

@media (min-width: 1480px) {
  /* #TODO EXPERIMENT UNIVERSALISE */
  /* tabbed content positioning */
  div.gunbot-dialog-wrapper.settings-pairs div[class*='tabContent-'] .inputGrid,
  div.gunbot-dialog-wrapper.settings-strategies div[class*='tabContent-'] .inputGrid {
    margin: 0 20px 20px 0;
  }

  /* global settings */
  div.gunbot-dialog-wrapper.settings-global div.tabContent-advanced div.inputGrid.withdraw_address {
    grid-row: 1;
  }

  div.gunbot-dialog-wrapper.settings-global div.tabContent-advanced div.inputGrid.debug {
    margin: 0 20px 20px 0;
  }
}

@media (min-width: 1920px) {
  /* profile settings */
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > div:last-child,
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > div:nth-last-child(2),
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > div > div:last-child,
  div.gunbot-dialog-wrapper.settings-profile div.tabContent-exchanges-content > div > div > div:nth-last-child(2) {
    grid-template-columns: repeat(auto-fill, minmax(570px, 1fr));
  }
}

body * {
  outline: none !important;
}

.without-bg.gunbot-dialog-wrapper {
  background: none;
  /*box-shadow: none;*/
}

.without-bg .gunbot-dialog-box {
  width: 100%;
  overflow: visible;
}

.without-bg .gunbot-dialog-wrapper {
  top: 0px;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.without-bg .gunbot-dialog-box > div:nth-child(2) > div[class^='makeStyles-cardBody-'] {
  padding-left: 0;
  padding-right: 0;
}

.without-bg .gunbot-dialog-box > div:nth-child(2) {
  width: 100%;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background: #1a1a1a;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.without-bg .gunbot-dialog-box > div:nth-child(2) > div:first-child {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.45), 0 7px 10px -10px rgba(0, 0, 0, 0.55);
  background: linear-gradient(60deg, #2f2f2f, #383737);
  margin-right: -5px;
  margin-left: -5px;
}

.without-bg .gunbot-dialog-box > div.MuiInputBase-root:last-child {
  width: 30vw;
  height: 63px;
  top: 6px;
  background: #3a3a3a;
}

.without-bg .closeButton {
  right: 12px;
  top: 12px;
}

/* Mui & popper styling */
div.MuiAutocomplete-popper,
div.MuiAutocomplete-popper .MuiPaper-root.MuiAutocomplete-paper {
  background-color: #1c1c1c;
}

div.MuiAutocomplete-popper ul.MuiAutocomplete-listbox {
  width: auto;
}

div.MuiAutocomplete-popper ul li.MuiAutocomplete-option {
  min-width: 150px;
}

li.MuiAutocomplete-option div span {
  color: white;
}

div.MuiAutocomplete-root .MuiAutocomplete-endAdornment {
  top: auto;
}

div.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  button.MuiAutocomplete-clearIndicatorDirty {
  display: none;
}

.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty {
  margin-left: 40%;
}

div.settingsForm.overrideSetting button {
  min-width: 25px;
}

div.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 25px;
}
/* 
div.MuiPopover-root div.MuiPopover-paper {
    width: 330px;
} */
g[aria-labelledby] {
  visibility: hidden;
}
g[aria-label='Legend'] g[aria-labelledby] {
  visibility: visible;
}
ul.MuiList-root.MuiMenu-list {
  background: #333;
}

.Toastify__toast-container {
  user-select: none;
  font-size: smaller;
  font-weight: 600;
  white-space: pre-line;
}

.Toastify__toast--success {
  background: #1b5e20;
}

.Toastify__toast--error {
  background: #b71c1c;
}

div[x-placement='bottom-start'] {
  z-index: 20;
}

body * {
  box-sizing: border-box;
}

div[role='tooltip'] {
  z-index: 30;
}

.overview-pair-card > div > div[class*='SP-params'] > div[class*='SP-param'] {
  margin-right: 0;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.04);
  height: 60px;
  padding: 0;
}

.overview-pair-card > div > div[class*='SP-params'] > div[class*='SP-param'] > div:first-child {
  padding: 5px 0;
  width: 120px;
  margin-left: 12px;
}

.overview-pair-card > div > div[class*='SP-params'] > div[class*='SP-param'] > div:last-child {
  max-width: unset;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-pair-card > div > div[class*='SP-params'] > div[class*='SP-param'] > div:last-child > div {
  height: 100%;
  width: 100%;
}

.overview-pair-card > div > div[class*='SP-params'] > div[class*='SP-param'] > div:last-child > div > div {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.overview-pair-card > div > div[class*='SP-params'] > div[class*='SP-param'] > div:last-child > div > div > span {
  top: 2px;
}

.overview-pair-card div[class*='SP-paramForm'] {
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.override-setting-panel > div > div[class*='SP-param '] {
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 4px;
  padding: 0 0 0 15px;
  height: 60px;
}

.override-setting-panel > div > div[class*='SP-param '] > div:nth-child(2) {
  flex: auto;
  display: flex;
  justify-content: center;
  max-width: unset;
}

.override-setting-panel > div > div[class*='SP-param '] > div:nth-child(2) input {
  height: 60px;
  box-sizing: border-box;
  min-width: unset;
}

.override-setting-panel > div > div[class*='SP-param '] > div:nth-child(2) .MuiFormControl-root {
  width: 100%;
}

.override-setting-panel > div > div[class*='SP-param '] > div:nth-child(3) {
  min-width: 36px;
}

.overview-pair-card div[class*='SP-param'] {
  margin-right: 0 !important;
  width: 100%;
}

.overview-pair-card div[class*='SP-paramForm'] > div {
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 0 !important;
}

.overview-pair-card div[class*='SP-paramForm'] > div > div {
  margin-right: 0;
  padding: 0;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.04);
}

.overview-pair-card div[class*='SP-paramForm'] > div > div > div:first-child {
  width: 120px !important;
  margin-left: 12px;
}

.overview-pair-card div[class*='SP-paramForm'] > div > div > div:last-child {
  flex: auto !important;
}

.overview-pair-card div[class*='SP-paramForm'] > div > div > div:last-child {
  max-width: unset;
}

.overview-pair-card div[class*='SP-paramForm'] > div > div > div:last-child > div {
  width: 100% !important;
}

.overview-pair-card div[class*='SP-paramForm'] > div > div > div:last-child > div > input {
  height: 100%;
  box-sizing: border-box;
}

.overview-pair-card div[class*='SP-paramForm'] > div > div > div:last-child > div .MuiInputLabel-outlined {
  transform: translate(30px, 25px) scale(1);
}

.overview-pair-card
  div[class*='SP-paramForm']
  > div
  > div
  > div:last-child
  > div
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, 2px) scale(0.75);
}

.overview-pair-card .MuiFormControl-root {
  margin: 0;
}

.tabContent-all > div > div > div.MuiGrid-container > .MuiGrid-item {
  margin-bottom: 40px;
}

.profile-trading-limit .SP-params {
  margin: 0;
}

.profile-add-new-trading-limit .SP-param {
  width: 100%;
  margin-bottom: 12px;
}

.trading-terminal-open-orders th,
.trading-terminal-open-orders td {
  padding: 6px 24px 6px 16px;
}
.trading-terminal-open-orders tr {
  height: auto;
}

/* .jss14:hover {
  color: #fff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.9) !important;
  background-color: #ffffff !important;
} */
